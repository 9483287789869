import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import { graphql } from "gatsby"
import BlogLayout from "../components/BlogContent/BlogLayout"
import "/src/assets/css/Blog/blog.css"
import SEO from "../components/App/SEO"

const BlogList = ({ data }) => {
  const blogPosts = data.postsPerPage.nodes
  const pageInfo = data.postsPerPage.pageInfo
  return (
    <Layout>
      <SEO title="Blog - Sitebunker.ro"/>
      <Navbar />
      <BlogLayout allPost={blogPosts} pageInfo={pageInfo} pageTitle="Blog"
                  pageDescription="Aici puteți găsi tutoriale și articole privind funcțiile și utilizarea pachetelor noastre de găzduire web precum și informații despre înregistrare domenii." />
      <Footer />
    </Layout>
  )
}


export const postListQuery = graphql`
  query postPerPage($skip: Int!, $limit: Int!) {
    postsPerPage: allStrapiBlog(
      sort: { fields: updated_at, order: DESC }
      limit: $limit
      skip: $skip
      filter: {category: {slug: {in: ["cpanel", "domenii", "general"]}}}
    ) {
        nodes {
          id
          title
          updated_at(formatString: "D MMMM, YYYY", locale: "ro")
          image {
            childImageSharp {
               fixed {
                  src
               }
            }
          }
          slug
          author
        }
        pageInfo {
          currentPage
          pageCount
          totalCount
        }
    }
  }
`

export default BlogList